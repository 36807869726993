/* ---------------========== home page css start ==========--------------- */
@media screen and (max-width: 1439px) {
	h2 {
		font-size: 130px;
	}

	.crafting-living-left .crafting-living-content p {
		font-size: 54px;
	}

	.about-us-content p {
		max-width: 22vw;
	}

	.maecenas-maximus-bottom-logo {
		padding: 50px 20px 9px;
	}

	.our-mission-text h3 {
		font-size: 4.45vw;
	}
}

@media screen and (max-width: 1199px) {
	h2 {
		font-size: 110px;
	}

	.main-menu .manu-left ul li a {
		font-size: 54px;
	}

	.main-menu .manu-left {
		padding: 108px 100px;
	}

	.manu-right ul {
		padding: 60px 50px 60px;
	}

	.manu-right .address-text {
		margin-left: 50px;
		margin-bottom: 50px;
	}

	.maecenas-maximus-textcontent p {
		font-size: 36px;
	}

	.crafting-living-video {
		padding: 32px;
	}

	.about-us-content {
		left: 4.2vw;
	}

	.future-living-wrapper h2 {
		right: 12.9vw;
	}

	.maecenas-maximus-item video {
		height: 450px;
	}

	.a-big-text {
		padding: 19.2vw 0 25.2vw;
	}
}

@media screen and (max-width: 1023px) {
	h2 {
		font-size: 100px;
	}

	.third-image-section .third-image-row .box-wrapper {
		padding: 15px;
	}

	.crafting-living-left .crafting-living-content p {
		font-size: 44px;
	}

	.crafting-living-video-left .crafting-living-deatils h3 {
		font-size: 30px;
		margin-bottom: 20px;
	}

	.maecenas-maximus-textcontent p {
		font-size: 30px;
	}

	.maecenas-maximus-textcontent {
		max-width: 523px;
	}

	.main-menu .manu-left ul li a {
		font-size: 48px;
	}

	.future-living-wrapper h2 {
		right: 9.9vw;
	}

	.maecenas-maximus-item video {
		height: 400px;
	}
}

@media screen and (max-width: 991px) {
	.main-menu .manu-left {
		padding: 108px 75px;
		width: 55%;
	}

	.main-menu .manu-left ul li a {
		font-size: 36px;
	}

	.manu-right .address-text {
		font-size: 20px;
	}

	.crafting-living-left .crafting-living-content p {
		font-size: 36px;
	}

	h2 {
		font-size: 75px;
	}

	.maecenas-maximus-textcontent p {
		font-size: 24px;
	}

	.maecenas-maximus-textcontent {
		max-width: 433px;
	}

	.crafting-living-video {
		padding: 20px;
	}

	.third-image-section .third-image-row .box-wrapper {
		padding: 10px;
	}

	.future-living-wrapper .about-us-content {
		left: 5.2vw;
	}

	.a-big-text {
		padding: 19.2vw 0 20.2vw;
	}
}

@media screen and (max-width: 767px) {
	.header-wrapper {
		padding: 18px 20px;
	}

	#myHeader.sticky .header-wrapper {
		padding: 12px 20px;
	}

	.header-left img {
		margin-left: 21px;
	}

	.menu-btn {
		width: 33px;
	}

	.header-right .outline-btn {
		font-size: 14px;
	}

	.outline-btn {
		max-width: fit-content;
		padding: 12px 18px;
		font-size: 13px;
	}

	.main-menu .manu-left ul li a {
		font-size: 24px;
		line-height: 2.3;
	}

	.main-menu .manu-left ul li a span {
		width: 7px;
		height: 7px;
	}

	.main-menu .manu-left {
		width: 100%;
		padding: 108px 75px 75px;
		border-right: 0;
	}

	.main-menu .main-menu-wrapper {
		flex-direction: column;
	}

	.main-menu .manu-right {
		width: 100%;
		border-top: 0.5px solid #00000026;
	}

	.manu-right .address-text {
		padding-top: 54px;
		padding-left: 75px;
		margin: 0;
	}

	.manu-right ul {
		padding: 42px 75px 42px;
		border-top: 0;
	}

	.hero-section {
		min-height: 600px;
	}

	.header-right {
		max-width: fit-content;
	}

	.third-image-section .third-image-row .box-wrapper {
		padding: 4px 5px;
	}

	h2 {
		font-size: 50px;
	}

	.future-living-wrapper .about-us-content {
		display: none;
	}

	.about-us-content.show-mobile {
		display: block;
		margin-top: 58px;
	}

	.about-us-content p {
		max-width: 238px;
	}

	.maecenas-maximus-textcontent p {
		max-width: 238px;
		font-size: 13px;
	}

	.third-image-section {
		padding: 58px 0 63px;
	}

	.crafting-living-content {
		padding: 72px 20px 200px;
	}

	.crafting-living-right {
		position: relative;
	}

	.a-big-text {
		position: absolute;
		bottom: 10px;
		left: 7.5px;
		max-width: 169px;
	}

	.a-big-text img {
		width: 100%;
	}

	.crafting-living-video-left .crafting-living-deatils {
		padding: 21px 20px;
	}

	.crafting-living-video {
		padding: 11.6px;
	}

	.new-standard-section {
		padding: 42px 20px 62px;
	}

	.new-standard-section .new-standard-one span {
		margin-right: 12px;
	}

	.new-standard-wrapper h2 {
		left: 0;
	}

	.new-standard-one span:nth-child(2) {
		display: inline-block;
	}

	.new-standard-one span:first-child {
		display: none;
	}

	.new-standard-wrapper {
		padding: 25px 0 11px;
	}

	.maecenas-maximus-bottom-logo {
		padding: 50px 20px 0;
	}

	.maecenas-maximus-bottom-logo img {
		width: 128px;
	}

	footer {
		padding-top: 64px;
	}

	.footer-wrapper {
		flex-direction: column;
		max-width: calc(100% - 40px);
		width: 100%;
		margin: 0 20px;
		padding: 55px 0 51px;
		border-top: 0.5px solid #ffffff;
	}

	.footer-left {
		max-width: 100%;
		border-right: 0;
		padding: 0;
	}

	.footer-right {
		max-width: 100%;
		padding: 34px 0 0;
	}

	.footer-left-tagline {
		display: none;
	}

	.footer-left-tagline.show-mobile {
		display: flex;
		justify-content: space-between;
		padding-bottom: 34px;
	}

	.footer-left-tagline p {
		max-width: 204px;
		width: 100%;
		font-size: 18px;
		margin-bottom: 0;
	}

	.footer-left-tagline img {
		width: 55px;
	}

	.footer-left .address-text {
		font-size: 18px;
	}

	.footer-left ul {
		display: none;
	}

	.social-icons.show-mobile {
		display: flex;
		gap: 48px;
		padding: 41px 0 38px;
		border-bottom: 0.5px solid #ffffff;
	}

	.footer-right-links a {
		font-size: 14px;
	}

	.footer-right-links a img {
		display: none;
	}

	.apt-residential-text {
		padding-top: 41px;
	}

	.maecenas-maximus-wrapper {
		max-width: 100%;
	}

	.maecenas-maximus-item video {
		height: 350px;
	}
}

@media screen and (max-width: 600px) {
	.maecenas-maximus-item video {
		height: 240px;
		border-radius: 10px;
	}

	.maecenas-maximus-item .video-content-text {
		font-size: 13px;
		max-width: 92px;
	}

	.owl-carousel .owl-item img {
		width: 42px;
	}

	.maecenas-maximus-slider .video-content-logo-bottom {
		bottom: 15px;
	}

	.maecenas-maximus-slider .video-content-logo-top {
		top: 15px;
	}

	.hero-section {
		min-height: 500px;
	}

	.crafting-living-content {
		padding: 72px 20px 158px;
	}
}

@media screen and (max-width: 500px) {
	.hero-section {
		min-height: 450px;
	}

	.home-page-hero img {
		width: 161.099px;
		top: 28px;
	}
}

@media screen and (max-width: 400px) {
	.hero-section {
		min-height: 369px;
	}
}

@media screen and (max-width: 380px) {
	.outline-btn {
		padding: 12px 14px;
	}

	h2 {
		font-size: 40px;
	}

	.maecenas-maximus-item video {
		height: 220px;
	}
}
/* ---------------========== home page css end ==========--------------- */

/* ---------------========== about us page css strat ==========--------------- */
@media screen and (max-width: 1439px) {
	.our-mission-text h3 {
		max-width: 40vw;
		font-size: 32px;
        line-height: 44px;
	}

	.our-purpose-line-one h3 {
		font-size: 4.45vw;
	}

	.join-the-team-textcontent h5 {
		font-size: 4.45vw;
	}

	.join-the-team-textcontent h5 {
		max-width: 43vw;
	}
}

@media screen and (max-width: 1023px) {
	.about-us-crafting-living-video .crafting-living-video-left .crafting-living-deatils {
		padding-left: 20px;
		padding-right: 20px;
	}

	.new-standard-section.about-ushero-section .new-standard-wrapper {
		padding: 42px 0 17px;
	}
}

@media screen and (max-width: 767px) {
	.new-standard-section.about-ushero-section {
		padding: 156px 20px 93px;
	}

	.new-standard-section.about-ushero-section h2 {
		justify-content: flex-start;
	}

	.new-standard-section.about-ushero-section .new-standard-wrapper {
		justify-content: center;
		gap: 9px;
	}

	.new-standard-section.about-ushero-section .new-standard-wrapper .new-standard-for-details {
		display: none;
	}

	.new-standard-section.about-ushero-section .new-standard-image {
		margin: 0;
	}

	.new-standard-section.about-ushero-section .new-standard-wrapper h2 {
		position: unset;
	}

	.new-standard-section.about-ushero-section .new-standard-image video {
		width: 139px;
		height: 62px;
	}

	.new-standard-section.about-ushero-section .new-standard-two {
		gap: 12px;
	}

	.new-standard-section.about-ushero-section p.show-mobile {
		display: block;
		font-family: HalenoirCompact-Light;
		font-size: 13px;
		font-style: normal;
		font-weight: 300;
		line-height: 1.3;
		max-width: 238px;
		width: 100%;
		padding-top: 55px;
	}

	.our-mission-text {
		margin-top: 56px;
	}

	.our-mission-text p span {
		width: 8px;
		height: 8px;
	}

	.our-mission-text p {
		font-size: 13px;
	}

	.our-mission-text h3 {
		margin-top: 32px;
		font-size: 32px;
		max-width: 278px;
		position: relative;
	}

	.our-mission-img-1 {
		position: absolute;
		left: 0;
	}

	.our-mission-img-2 {
		position: absolute;
		right: 0;
	}

	.our-mission-img-2 img {
		margin-top: 0;
	}

	.our-mission-row-one {
		justify-content: center;
		margin-left: 0;
	}

	.our-mission-img-1 img {
		left: 30px;
		top: 0;
	}

	.our-purpose-line-one {
		flex-direction: column;
		padding: 74px 20px 69px;
	}

	.our-purpose-line-one h3 {
		font-size: 32px;

		margin-bottom: 30px;
	}

	.our-purpose-text .sub-title span {
		width: 8px;
		height: 8px;
	}

	.our-purpose-text .sub-title {
		margin-bottom: 8px;
	}

	.our-purpose-line-two .our-purpose-a-logo img {
		width: 169px;
		max-width: 169px;
		position: absolute;
	}

	.our-purpose-line-two {
		padding: 0;
		padding-right: 20px;
		position: relative;
	}

	.founders-list-wrapper {
		max-width: 236px;
		margin-left: 0;
		padding-bottom: 60px;
	}

	.founders-list-wrapper .founders-list-main {
		flex-direction: column;
		gap: 30px;
	}

	.founders-list-main h4 {
		font-size: 18px;
	}

	.founders-list-main p {
		font-size: 13px;
	}

	.about-us-crafting-living-video .crafting-living-video-left .crafting-living-deatils {
		display: none;
	}

	.about-us-crafting-living-video .crafting-living-video-left .crafting-living-deatils {
		display: none;
	}

	.about-us-crafting-living-video .crafting-living-video-left .crafting-living-deatils.show-mobile {
		display: block;
		padding-top: 57px;
	}

	.about-us-crafting-living-video .crafting-living-video-left .crafting-living-deatils h3 {
		font-size: 18px;
		margin-bottom: 7px;
	}

	.about-us-crafting-living-video .crafting-living-video-left .crafting-living-deatils p {
	}

	.join-the-team-wrapper video {
		height: 415px;
	}

	.join-the-team-textcontent {
		bottom: 65px;
		padding: 0 20px;
		left: 0;
	}

	.join-the-team-textcontent h5 {
		max-width: 100%;
		font-size: 32px;
		margin-bottom: 25px;
	}
}
/* ---------------========== about-us page css end ==========--------------- */

/* ---------------========== Our expertise page css start ==========--------------- */
@media screen and (max-width: 1199px) {
	.our-expertise-content-section p {
		font-size: 28px;
		max-width: 82vw;
	}

	.view-our-team-one {
		padding-left: 6.7vw;
	}

	.view-our-team-two {
		padding-left: 6vw;
		padding-right: 2.2vw;
	}
}

@media screen and (max-width: 991px) {
	.video-and-text-wrapper h2 {
		top: 50%;
		transform: translateY(-50%);
		left: 10vw;
	}

	.our-community-hero-section .video-and-text-wrapper h2 {
		top: 45%;
		transform: translate(0);
	}

	.our-expertise-content-section {
		padding-top: 77px;
	}

	.our-expertise-content-section p {
		font-size: 22px;
		max-width: 80vw;
	}

	.view-our-team-one {
		padding: 0 20px;
		gap: 40px;
	}

	.view-our-team-two {
		padding: 0 20px;
		gap: 40px;
		margin-top: 3vw;
	}
}

@media screen and (min-width: 768px) {
	.view-our-team-image .view-our-team-1 {
		max-width: 34vw;
		height: 22.7vw;
	}

	.view-our-team-image .view-our-team-2 {
		max-width: 34vw;
		height: 22.5vw;
	}

	.view-our-team-image .view-our-team-3 {
		max-width: 34.2vw;
		height: 23vw;
	}
}

@media screen and (max-width: 767px) {
	.our-expertise-content-section p {
		font-size: 18px;
		max-width: 320px;
		text-transform: math-auto;
		text-align: center;
	}

	.our-expertise-content-images {
		padding: 92px 0 0;
	}

	.our-expertise-content-images .our-expertise-a-bg {
	}

	.our-expertise-content-images .our-expertise-a-bg .our-expertise-a-text {
		width: 78.368px;
		height: auto;
	}

	.our-expertise-content-section {
		padding-bottom: 0;
	}

	.view-our-team-one,
	.view-our-team-two {
		padding: 0;
		flex-direction: column;
		gap: 56px;
	}

	.content-title br {
		display: none;
	}

	.view-our-team-button {
		justify-content: flex-start;
		margin-top: 58px;
	}
}
/* ---------------========== Our expertise page css end ==========--------------- */

/* ---------------========== our approach page css start ==========--------------- */
@media screen and (max-width: 1439px) {
	.our-values-text {
		max-width: 47.8vw;
	}

	.our-values-text h3 {
		font-size: 4.45vw;

	}

	.our-values-section {
		padding: 8vw 5vw;
	}
}

@media screen and (max-width: 1199px) {
	.our-values-content-2 {
		margin-left: 11.4vw;
	}
}

@media screen and (max-width: 1023px) {
	.our-values-content-2 {
		max-width: 400px;
	}
}

@media screen and (max-width: 991px) {
	.our-values-content-2 {
		max-width: 350px;
		margin-left: 5.4vw;

	}

	.our-values-content-3 {
		max-width: 370px;
	}

	.our-values-content-4 {
		margin-right: 0;
		max-width: 370px;
	}

	.our-values-section {
		padding: 58px 20px;
	}
}

@media screen and (max-width: 767px) {
	.our-values-section {
		max-width: 400px;
		margin: 0 auto;
		width: 100%;
		padding-bottom: 0;
	}

	.our-values-text p {
		gap: 9px;
	}

	.our-values-text p span {
		width: 8px;
		height: 8px;
	}

	.our-values-first-row {
		flex-direction: column-reverse;
	}

	.our-values-text {
		margin-left: 0;
		margin-bottom: 50px;
	}

	.our-values-text {
		max-width: 100%;
	}

	.our-values-content-two {
		flex-direction: column-reverse;
	}

	.our-values-content-3 {
		max-width: 224px;
		margin-top: -22vw;
		margin-left: 0;
	}

	.our-values-content-4 {
		max-width: 224px;
		margin-bottom: -16vw;
	}
}

@media screen and (max-width: 369px) {
	.text-character-a {
		margin-left: -15vw;
	}





	.our-values-content-3 {
		max-width: 201px;
	}

	.our-values-content-4 {
		max-width: 200px;
	}

	.text-character-t {
		margin-left: -8.5vw;
	}

	.video-and-text-wrapper video {
		min-height: 400px;
	}
}
/* ---------------========== our approach page css end ==========--------------- */

/* ---------------========== our community page css end ==========--------------- */

@media screen and (max-width: 1439px) {
	.social-responsibility-images {
		max-width: 25.3vw;
	}
}

@media screen and (max-width: 991px) {
	.dolor-sitamet-1 {
		margin-bottom: 30px;
	}
}

@media screen and (max-width: 767px) {
	.our-community-hero-section .video-and-text-wrapper h2 {
		left: 43px;
		top: 50%;
	}

	.our-values-section.our-community-content-section {
		padding: 58px 20px 55px;
		max-width: 100%;
	}

	.our-community-content-section .our-values-first-row {
		flex-direction: column-reverse;
	}

	.our-community-content-section .our-values-text {
		margin-left: 0;
	}

	.community-hero-image {
		display: flex;
		justify-content: end;
	}

	.dolor-sitamet-1 {
		margin-left: 0;
		margin-bottom: 50px;
	}

	.dolor-sitamet-img-2 img {
		width: 103px;
		height: 59px;
	}

	.dolor-sitamet-text-1 p {
		max-width: 232px;
		margin-top: 18px;
	}

	.dolor-sitamet-text-2 p {
		max-width: 232px;
		margin-top: 18px;
		margin-left: 30px;
	}

	.dolor-sitamet-text-3 p {
		max-width: 224px;
		padding-top: 18px;
	}

	.dolor-sitamet-img-4 {
		bottom: -60px;
	}

	.social-responsibility-section {
		padding: 87px 20px;
	}

	.social-responsibility-wrapper {
		margin-top: 20px;
		margin-bottom: 20px;
		justify-content: center;
	}
}

@media screen and (max-width: 369px) {
	.dolor-sitamet-text-1 p {
		max-width: 192px;
	}

	.dolor-sitamet-text-2 p {
		max-width: 182px;
		margin-top: 18px;
		margin-left: 20px;
	}

	.dolor-sitamet-img-3 {
		top: -24.5vw;
	}

	.dolor-sitamet-text-1 {
		margin-left: 2.8vw;
	}
}

/* ---------------========== our approach page css end ==========--------------- */

/* ---------------========== contact us page css start ==========--------------- */

/* --------------- contact us form section start --------------- */
@media screen and (max-width: 991px) {
	.contact-us-form-wrapper {
		margin: 0;
	}

	.contact-us-form-left {
		padding-left: 20px;
		padding-right: 20px;
	}

	.contact-us-form-right {
		padding-left: 20px;
		padding-right: 20px;
	}

	.contect-us-map {
		padding-right: 0;
	}

	.contact-us-title {
		padding: 0 20px;
	}

	.contact-us-title h2 {
		margin-bottom: 20px;
	}
}

@media screen and (max-width: 767px) {
	.contact-us-title {
		padding: 0;
	}

	.contact-us-title p {
		display: none;
	}

	.contact-us-title h2 {
		margin-bottom: 25px;
	}

	.contact-us-form-wrapper {
		display: flex;
		flex-direction: column;
		padding: 0;
	}

	.contact-us-form-left {
		border-right: 0;
		max-width: 100%;
		padding: 0;
	}

	.contact-details {
		padding: 52px 0;
	}

	.contact-details-item a {
		font-size: 24px;
		margin-bottom: 0;
		padding-bottom: 52px;
		border-bottom: 1px solid #111;
	}

	.contact-us-form-left .contect-us-map {
		display: none;
	}

	.contact-us-form-right {
		max-width: 100%;
		padding: 0 0 63px 0;
	}

	.contact-us-form-right .show-mobile {
		display: block;
	}

	.contact-us-form-right .show-mobile p {
		font-size: 24px;
		font-style: normal;
		font-weight: 400;
		padding-bottom: 37px;
		border-bottom: 1px solid #111;
		margin-bottom: 15px;
	}

	.input-field label {
		padding-left: 0;
	}

	.input-field textarea,
	.input-field input {
		padding-left: 0;
	}

	.your-message-text textarea {
		height: 120px;
	}

	.send-message-btn {
		justify-content: center;
		margin-top: 30px;
	}

	.send-message-btn a {
		max-width: 149px;
	}

	.contect-us-map.show-mobile {
		display: block;
		height: 515px;
		max-width: 100%;
	}

	.contect-us-map.show-mobile iframe {
		height: 100%;
	}

	.contect-us-map img:first-child {
		height: 100%;
		object-fit: cover;
	}

	.contact-us-form-section {
		padding: 148px 20px 0;
	}
}

/* --------------- contact us form section end --------------- */

/* ---------------========== contact us page css end ==========--------------- */

/* ---------------========== News page css start ==========--------------- */
@media screen and (max-width: 1199px) {
	.first-products-article-item {
		padding: 25px 20px;
	}

	.first-products-article-item p {
		font-size: 20px;
	}

	.news-hero-section {
		padding-bottom: 24vw;
	}

	.blog-list-item p {
		font-size: 18px;
	}
}

@media screen and (max-width: 991px) {
	.first-products-article-item {
		max-width: 60vw;
	}

	.first-products-article-item p {
		font-size: 18px;
		padding: 15px 0;
	}

	.news-hero-section {
		padding-bottom: 30vw;
	}

	.blog-list {
		grid-template-columns: 1fr 1fr;
		gap: 4vw;
	}
}

@media screen and (max-width: 767px) {
	.news-hero-section .video-and-text-wrapper h2 {
		left: 12.3vw;
		top: 45%;
		transform: translate(0);
		text-align: left;
	}

	.first-products-article-item {
		max-width: 346px;
		transform: translate(-50%, 70%);
	}

	.first-products-article-item img {
		width: 100%;
		height: 306px;
		object-fit: cover;
	}

	.news-hero-section {
		padding-bottom: 380px;
	}

	.blog-list {
		grid-template-columns: 1fr;
		gap: 25px;
	}

	.blogs-main {
		padding: 20px 20px 73px;
		max-width: 400px;
	}

	.blog-list-item {
		padding: 25px 20px;
	}
}

@media screen and (max-width: 369px) {
	.first-products-article-item {
		max-width: calc(100% - 40px);
	}
}
/* ---------------========== News page css end ==========--------------- */

/* ---------------========== Article Open css start ==========--------------- */
@media screen and (max-width: 1439px) {
	.other-recent-articles-section {
		max-width: calc(100% - 40px);
	}

	.article-open-row-two img,
	.article-open-main img {
		width: 100%;
	}
}

@media screen and (max-width: 1199px) {
	.main-article-section {
		padding: 10vw 20px 6.5vw;
	}

	.article-open-text-content {
		max-width: 48%;
	}

	.article-open-two-left {
		max-width: 37.3vw;
	}

	.article-open-main h3 {
		padding-top: 40px;
	}

	.main-article-section h3 {
		font-size: 28px;
	}
}

@media screen and (max-width: 991px) {
	.main-article-section {
		padding: 90px 20px 6.5vw;
	}

	.article-open-main h3 {
		padding-top: 20px;
	}

	.article-open-wrapper {
		margin-top: 24px;
	}

	.article-open-text-content {
		max-width: 49%;
	}

	.article-open-text-content P {
		margin-bottom: 16px;
	}
}

@media screen and (max-width: 767px) {
	.main-article-section {
		padding: 80px 20px 50px;
	}

	.main-article-section h3 {
		font-size: 18px;
		margin-top: 20px;
		margin-bottom: 28px;
	}

	.article-open-text-content P {
		font-size: 14px;
	}

	.article-open-wrapper {
		margin-top: 16px;
	}

	.article-open-main h3 {
		padding-top: 20px;
		margin-bottom: 16px;
	}

	.article-open-wrapper {
		flex-direction: column;
	}

	.article-open-text-content {
		max-width: 100%;
	}

	.article-open-row-two {
		padding-top: 40px;
		flex-direction: column;
	}

	.article-open-two-left {
		max-width: 100%;
	}

	.other-recent-articles-section .our-values-text {
		padding-left: 0;
	}

	.other-recent-articles-section {
		padding-top: 70px;
		padding-bottom: 50px;
	}

	.article-open-only-text p {
		margin-bottom: 16px;
	}
}
/* ---------------========== Article Open css end ==========--------------- */

@media screen and (max-width: 1599px) and (min-width: 1200px) {
	.owl-stage {
		margin-left: -160px;
	}
}
